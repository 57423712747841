import { Box, Button, CircularProgress, ListItem, ListItemText, Stack, Typography } from "@mui/material"
import { useContext, useState } from "react"
import {GetAllProfileRoles, getMinistryData, GetUserCustomClaims, getUserSpecificRoleRecord} from '../../GetProfileData'
import { useQuery } from "react-query"
import { RoleType } from "./rolesType"
import { MinistryType } from "./ministryType"
import { UserRoleRecordType } from "./userType"
import { auth } from "../../../Status/FirebaseConfig"

export const ProfileRoles = () => {
    
    const [currentClaims, setCurrentClaims] = useState<RoleType[]>([])
    const [ministries, setMinistries] = useState<MinistryType[]>([])
    const [record, setRecord] = useState<UserRoleRecordType[]>([])
    const [loading, setLoading] = useState(false)
    const claimsList = async () => {
        setLoading(true)
        let userClaims = await GetUserCustomClaims()
        let userClaimList: string[] = userClaims?.claim as string[]
        let claims = await GetAllProfileRoles(userClaimList)
        let recordData = await getUserSpecificRoleRecord(userClaimList, auth.currentUser?.uid ?? "")
        setRecord(recordData)
        setCurrentClaims(claims[0] as RoleType[])
        let minDta = await getMinistryData(claims[1])
        setMinistries(minDta)
        setLoading(false)
    }

    const {} = useQuery({
        queryKey: ["custom_claims"],
        queryFn: async () =>{
            await claimsList()
        }
    })
    

    const handleExpireData = (claimId: string) => {
        let expiryData = record.find((x) => x.claim === claimId)?.expires
        if(expiryData) {
            return new Date(expiryData).toLocaleString()
        } else {
            return "Does Not Expire"
        }
    }
    const handleGrantedData = (claimId: string) => {
        let grantedData = record.find((x) => x.claim === claimId)?.date
        if(grantedData) {
            return new Date(grantedData).toLocaleString()
        } else {
            return "N/A"
        }
    }

    return (
        <Box paddingX={5}>
            {
                loading ?
                <Box textAlign={'center'}>
                    <CircularProgress />
                </Box>
                :
                <>
            
            {
                currentClaims.length > 0
                ?
                <Stack direction="row" spacing={2}>
                    {currentClaims.map((value) => (
                <ListItem alignItems='center'>
                    <ListItemText primary={<b>{value.title}</b>}/>
                    <ListItemText primary={ministries.find((x) => x.ministryId === value.ministryId)?.title} secondary={"Ministry"}/>
                    <ListItemText primary={handleGrantedData(value.claimId)} secondary={'Granted'}/>
                    <ListItemText primary={handleExpireData(value.claimId)} secondary={'Expires'}/>
                </ListItem>
            ))}
                </Stack>
                :
                <Typography><b>No Assigned Roles</b></Typography>
            }
            </>
}
        </Box>
    )
}