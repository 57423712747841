import { Grid, Switch, Box, AlertColor } from "@mui/material"
import {sendgridEmailSettings, sendgridUnsubscribe, sendgridSubscribe} from '../../ProfileSecurityChanges'
import { useQuery } from "react-query"
import { StatusContext } from "../../../../App"
import { useState, useContext } from "react"
import { ProfileMessageContext } from "./ProfileHome"
type emailSettingsType = {
    description: string | undefined
    name : string | undefined
    id: number
    is_default : boolean
    suppressed: boolean
}

// an important note:
// SendGrid counts "suppressions" i.e. where if suppressed, emails do not go to that address
// however, since "Suppressed Emails" is harder for the end-user to understand
// Red Sea counts whether or not an Email is "allowed" to be sent. Basically the reverse of what SendGrid does
// because of this, the <function_name> function converts from a "allowed/not allowed" to a "suppressed/not suppressed" system

export const ProfileNotifications = () => {
    const [emailSettings, setEmailSettings] = useState<emailSettingsType[]>()
    const {notification, setNotification} = useContext(ProfileMessageContext)
    const {currentStatus, setCurrentStatus} = useContext(StatusContext)
    const {data, isLoading} = useQuery({
        queryKey: ["profileData"],
        queryFn: () => async () =>{
            await getEmailSettings()
        }
    })

    const getEmailSettings = async() => {
        let emailSendgridSettings = await sendgridEmailSettings(currentStatus?.email as string)
        setEmailSettings(emailSendgridSettings.data.suppressions)
    }
    const handleNotificationSettingChange = async (id: number, checked: boolean) => {
        let settings = emailSettings
        let index = settings?.findIndex((x: any) => x.id === id)
        if(index !== -1 && settings && index !== undefined && currentStatus.email) {
          settings[index].suppressed  = !checked
          setEmailSettings([... settings])
          try {
          if(checked) {
            // if checked -> unsuppressed so need to subscribe to emails
            await sendgridSubscribe(currentStatus?.email, id)
          } else {
            // if unchecked -> suppressed so need to unsubscribe
            await sendgridUnsubscribe(currentStatus.email, id)
          }
          notificationChangeMessage("Email Settings Updated")
        } catch(error) {
            console.log(error)
           notificationChangeMessage("Error Occurred", "error")
        }
        }
    }

    const notificationChangeMessage = (messageValue: string, severityValue?: AlertColor) => {
        setNotification({...notification, message: messageValue, show: true, severity: severityValue || "success"})
    }
    return (
    <>
    <Box >
    <Grid container columnGap={5} paddingX="70px">
     {emailSettings ? emailSettings?.map((value, index) => (
        <Grid item key={value.id} width="300px">
            <Box justifyContent={'space-between'} display="flex" alignItems={'center'}>
                <label><b>{value.name}</b></label>
                <Switch checked={!value.suppressed} onClick={(e: any) => handleNotificationSettingChange(value.id, e.target.checked)} />
            </Box>
            
            <p>{value.description}</p>
        </Grid>
       )) : <></> }
    </Grid>
    </Box>
    </>
    )
}