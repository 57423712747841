import { Box, Typography, Alert, AlertColor, AlertTitle} from "@mui/material"
import '../../../../Fonts.css'
import { useState, createContext, useContext, SetStateAction, Dispatch, useEffect } from "react"
import { BrandColors, BrandType } from "../../../../Constants"
import { ProfileSideBar } from "./ProfileSideBar"
import {ProfileOptionsObjectType, ProfileOptions} from '../ProfileOptions'
import {CurrentSizeContext, StatusContext} from '../../../../App'

export type NotificationMessageType = {
    message: string | ""
    title: string | ""
    severity: AlertColor
    show: boolean,
    timeout: number
}

const NotificationMessageDefault = {
    message: "",
    title: "",
    severity: "success" as AlertColor,
    show: false,
    timeout: 3000
}

interface NotificationMessageTypeInterface {
    notification: NotificationMessageType,
    setNotification: Dispatch<SetStateAction<NotificationMessageType>>
  }


export const ProfileMessageContext = createContext<NotificationMessageTypeInterface>({
    notification: NotificationMessageDefault,
    setNotification: (value: any) => {}
})


export const ProfileHome = (props: any) => {
    const CurrentUser = useContext(StatusContext)
    const Size = useContext(CurrentSizeContext)
    const [notification, setNotification] = useState<NotificationMessageType>(NotificationMessageDefault)

    const [currentPage, setCurrentPage] = useState<ProfileOptionsObjectType>(ProfileOptions[0])

    useEffect(() => {
        setTimeout(() => {
            setNotification({...notification, show: false})
        }, notification.timeout ? notification.timeout : 3000)
    }, [notification.message])
    return (
        <>
       <Box sx={{
                marginLeft: '60px',
                paddingTop: '30px',
            }}>
                <Typography variant="h5">
                Account Settings
                </Typography>
                <Typography variant="body2" color={BrandColors.GRAY.LIGHT}>
                Update your profile & notification settings
                </Typography>
            </Box>

        <Box sx={{
         //   visibility: 'hidden',
            paddingY: '20px'
        }}>
        <Alert 
        severity={notification?.severity}
        sx={{
            display: notification?.show ? "" : "none"
        }}>
            {notification?.title ? <AlertTitle>{notification.title}</AlertTitle> : <></>}
            {notification?.message}
        </Alert>
        </Box>
            <Box
            sx={{
                marginX: 'auto',
                minWidth: '1000px',
                width: '60vw',
                minHeight: '600px',
                height: '20vw',
                borderRadius: 5,
                boxShadow: 3,
                marginBottom: '80px',
                display: 'flex'
            }}
            >
                
                <Box sx={{
                    paddingTop: '30px',
                    width: '200px',
                    height: '95%',
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    bgcolor: BrandColors.GRAY.VERY_LIGHT,
                }}>
                
               <ProfileSideBar currentPage={currentPage} onPageChange={(requestedPage: ProfileOptionsObjectType) => setCurrentPage(requestedPage)}/>
               </Box>
               <Box sx={{
                width: '100%',
                height: '100%'
               }}>
                <Box 
                sx={{
                    paddingTop: '30px',
                    textAlign: 'center',
                    fontWeight: BrandType._WEIGHT.SEMIBOLD,
                    fontSize: '1.4em',
                    marginBottom:'40px'
                }}>
                    {currentPage.title}
                </Box>
                <ProfileMessageContext.Provider value={{notification, setNotification}}>
               {currentPage.page}
               </ProfileMessageContext.Provider>
               </Box>
               </Box>
        </>
    )
}