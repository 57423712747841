import {db} from '../Status/FirebaseConfig'
import {getDoc, doc, getDocs, query, collection, where, and} from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import axios from 'axios';
import { app } from '../Status/FirebaseConfig';
import { getAuth } from 'firebase/auth';
const storage = getStorage();


//gets the user's profile data, that isn't included in the base firebase users instance
//for example , first name and last name. Default Firebase instance only has "name"
export const GetProfileData = async (uid: string) => {
    const docRef = doc(db, "identity", "users", "account_metadata", uid);

    const docSnap = await getDoc(docRef);

    if(docSnap.exists()) {
        return docSnap.data()
    }
    
}


export const UploadImageBlob = async (data: Blob, uid: string, extension:string) => {
    let pathReference = ref(storage, `/Identity/${uid}/profile_picture.` + extension);
    try {
        return await uploadBytes(pathReference, data)
    } catch(error) {
        console.log(error)
    }
}

// from the firebase path, generates a download url for the user's profile picture
export const GetNewImageUrl = async (path: string) => {
    try {
    return await getDownloadURL(ref(storage, path))
    } catch(error) {
        console.log(error)
    }
}

//updateProfilePictureLink_post
export const UpdatePfpLink = async (uid: string, link: string | null) => { 
    return await axios.post('https://identity.fwcpchurch.org/_auth/updatePfpLink',{
        uid: uid,
        photoUrl: link
    })
}

export const GetAllProfileRoles = async (roles: string[]) => {
    let array:any[] = []
    let min: string[] = []
    const ref = collection(db, "identity", "claims", "list")
    const queryParams = query(ref, where("claimId", "in", roles))
    const docSnap = await getDocs(queryParams)
    docSnap.forEach((doc) => {
        array.push(doc.data())
        if(!min.includes(doc.data().ministryId)) {
            min.push(doc.data().ministryId)
        }
    })
    return [array, min]
}

export const GetUserCustomClaims = async () => {
    let user = await getAuth(app).currentUser?.getIdTokenResult()
    return user?.claims
}

export const getMinistryData = async (ministries: string[]) => {
    let array:any[] = []
    const ref = collection(db, "engrafo", "ministry_data", "list")
    const queryParams = query(ref, where("ministryId", "in", ministries))
    const docSnap = await getDocs(queryParams)
    docSnap.forEach((doc) => {
        array.push(doc.data())
    })
    return array
}

export const getUserSpecificRoleRecord = async (roles: string[], uid: string) => {
    let array:any[] = []
    const ref = collection(db, "identity", "claims", "record")
    const queryParams = query(ref, and(where("claim", "in", roles), where("uid", "==", uid)))
    const docSnap = await getDocs(queryParams)
    docSnap.forEach((doc) => {
        if(!doc.data().status) {
            array.push(doc.data())
        }
    })
    return array
}