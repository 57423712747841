import { ProfileHeader } from "./ProfileDesktop/ProfileHeader"
import { ProfileHome } from "./ProfileDesktop/ProfileHome"
import {useContext} from 'react'
import { CurrentSizeContext } from "../../../App"
import { ProfileMobileHome } from "./ProfileMobile/ProfileMobileHome"


export const Profile = (props: any) => {

    const CurrentSize = useContext(CurrentSizeContext)

    return (
        <>

        <ProfileHeader />
        {CurrentSize.tablet ?
         <ProfileMobileHome /> :
        <ProfileHome/>
        
    }
 </>
    )
}