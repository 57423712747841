import {Box, Button, Grid, IconButton} from '@mui/material'
import { Clear } from '@mui/icons-material'
import { FileDragAndDrop } from '../../ProfileDesktop/Modal/FileDragAndDrop'

export const MobileImageUpload = (props: any) => {
    const FileData =() => {
        let pathName = props.currentImage?.path
        return(
            <Box>
            <Grid container direction="row" justifyContent={'space-between'} alignItems={'center'} marginY={'3vh'} border={1} >
                <Grid item>
                    <Box textAlign={'center'}>
                    {pathName.substring(0,15)}...{pathName.substring(pathName.length - 6)}
                    </Box>
                </Grid>
                <Grid item>
                <IconButton>
                    <Clear />
                </IconButton>
                </Grid>
            </Grid>

                <Button variant={'outlined'} fullWidth onClick={props.goToEdit}>
                    Next
                </Button>
                
            </Box>
            
        )
    }
    return (
        <>
        <FileDragAndDrop clear={props.clearFile} saveImage={(file:File) => props.saveImage(file)}/>

        <Box>
           {props.currentImage ? <FileData /> : <></>}
        </Box>
        </>
    )
}