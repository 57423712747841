import React, { Dispatch, SetStateAction } from 'react';
import {CurrentLoginStatus, AlreadyLogInCheck, LoginWithSession} from './pages/Status/CheckAuthStatus';
import { Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import theme from './Theme'
import { LoginSignUpToggle } from './pages/LoginSignUpToggle';
import { useQuery } from 'react-query';
import { CurrentUserType, CurrentUserDefault } from './Constants';
import { useLocation } from 'react-router-dom';


export const StatusContext = React.createContext<CurrentStatusContextInterface>({
  currentStatus: CurrentUserDefault,
  setCurrentStatus: (value: any) => {}})

type CurrentSize = {
  nano: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  display: boolean;
}
const defaultState = {
  nano: false,
  mobile: true,
  tablet: false,
  desktop: false,
  display: false
}

interface CurrentStatusContextInterface {
  currentStatus: CurrentUserType,
  setCurrentStatus: Dispatch<SetStateAction<CurrentUserType>>
}

export const CurrentSizeContext = React.createContext<CurrentSize>(defaultState)
function App() {
  const location = useLocation()
  const [currentStatus, setCurrentStatus] = React.useState<CurrentUserType>(CurrentUserDefault)
  const [confirmingLogin, setConfirmingLogin] = React.useState(false)
  const confirmLoginStatus = async () => {
    setConfirmingLogin(true)
    let status = await CurrentLoginStatus()
    setCurrentStatus(status)
    setConfirmingLogin(false)
  }
  const {data, isLoading} = useQuery({
    queryKey: ["logstat"],
    queryFn: () => checkPreviousSession()
  })

  const checkPreviousSession = async() => {
   
    if(window.location.hostname !== "localhost") {
      setConfirmingLogin(true)
    try {
      let loginStatus = await AlreadyLogInCheck()
      if(loginStatus.status == 200 && loginStatus.data !== "") {
        let sessionStatus = await LoginWithSession(loginStatus.data)
        setCurrentStatus(sessionStatus)

      }
      setConfirmingLogin(false)
    } catch (e: any) {
      if(e.response.data == "USER_NOT_AUTHENTICATED" && window.location.pathname == "/logout") {
        window.location.href = "/"
      }
      setConfirmingLogin(false)
    }
  } else {
    //setIsLoggedIn(LocalHostUserData)
  }
  }
  let pageDefaultSizes = {
    nano: useMediaQuery(theme.breakpoints.down('xs')),
    mobile: useMediaQuery(theme.breakpoints.down('sm')),
    tablet: useMediaQuery(theme.breakpoints.down('md')),
    desktop: useMediaQuery(theme.breakpoints.down('lg')),
    display: useMediaQuery(theme.breakpoints.down('xl')),
  }
  return (
    <>
    {
      isLoading ?
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
      :

    
    <ThemeProvider theme={theme}>
    <StatusContext.Provider value={{currentStatus, setCurrentStatus}}>
    <CurrentSizeContext.Provider value={pageDefaultSizes}>
    <LoginSignUpToggle
    checkLogin={() => confirmLoginStatus()}
    confirmingLogin={confirmingLogin}
    />

    </CurrentSizeContext.Provider>
    </StatusContext.Provider>
    </ThemeProvider>
}
    </>
  );
}

export default App;
