import engrafoBow from './img/Bow Logos/engrafo_bow.png'
import identityBow from './img/Bow Logos/identity_bow.png'
//import worshipBow from './img/Bow Logos/worship_bow.png'
//import eventsBow from './img/Bow Logos/events_bow.png'
//import officersBow from './img/Bow Logos/officers_bow.png'
import symphonyBow from './img/Bow Logos/symphony_bow.png'


export const EngrafoApps = [
    {
        title: "Engrafo",
        img: engrafoBow,
        link: "https://engrafo.fwcpchurch.org",
        active: true
    },
    /*
    {
        title: "Worship",
        img: worshipBow,
        link: "https://admin.worship.fwcpchurch.org",
        active: true
    },
    */
    {
        title: "Symphony",
        img: symphonyBow,
        link: "https://symphony.fwcpchurch.org",
        active: true
    },
    /*
    {
        title: "Officers",
        img: officersBow,
        link: "https://officers.fwcpchurch.org",
        active: false
    },

    {
        title: "Events",
        img: eventsBow,
        link: "https://events.fwcpchurch.org",
        active: false
    },
    */
    {
        title: "Identity",
        img: identityBow,
        link: "https://identity.fwcpchurch.org",
        active: true
    }
]