import AvatarEditor from "react-avatar-editor"
import { useState, useRef } from "react"
import { Slider, Box, Button, Grid } from "@mui/material"
import { ArrowBack, Check, Save } from "@mui/icons-material"
import { GetNewImageUrl, UpdatePfpLink, UploadImageBlob } from "../../../GetProfileData"
import { LoadingButton } from "@mui/lab"
import { BrandColors } from "../../../../../Constants"
export const MobileAdjustImage = (props: any) => {

    const [imgScale, setImgScale] = useState(1.0)
    const [loading, setLoading] = useState(false)
    const [loadingFinished, setLoadingFinished] = useState(false)
    const cropRef = useRef<AvatarEditor | null>(null)

    const handleSave = async () => {
      setLoading(true)
      if(cropRef.current) {
          const dataUrl = cropRef.current.getImage().toDataURL()
          const result = await fetch(dataUrl)
          const blob = await result.blob()
          let extension = String(blob.type.split('/').pop())
          let uploadData = await UploadImageBlob(blob, props.uid, extension)
          if(uploadData?.metadata) {
            let imageUrlNew = await GetNewImageUrl(uploadData.metadata.fullPath)
            if(imageUrlNew) {
              await UpdatePfpLink(props.uid, imageUrlNew)
              props.afterSave()
              setLoadingFinished(true)
            }
            setLoading(false)
            props.afterSave()
          }
      }
    }

    return (
        <>
        <Box sx={{textAlign: 'center'}}>
        <AvatarEditor
          ref={cropRef}
            image={props.currentImage}
            width={250}
            height={250}
            border={10}
            borderRadius={999}
            color={[0, 0, 0, 0.3]} // RGBA
            scale={imgScale}
            rotate={0}
          />
          </Box>
          <Box textAlign={'center'} paddingY={'3vh'} >
            <Box>
            Scale: {imgScale}x
            </Box>
           <Slider
              defaultValue={1}
              aria-label="Default"
              max={5}
              min={0.5}
              step={0.05}
              valueLabelDisplay="auto"
              value={imgScale}
              onChange={(event, value) => setImgScale(value as number)}
            />
          </Box>
            <Grid container direction="row" justifyContent={'space-between'}>
              <Grid item>
              <Button variant="outlined" startIcon={<ArrowBack />} onClick={props.goBack}>
              Back
            </Button>
              </Grid>
              {!loadingFinished ? 
              <Grid item>
              <LoadingButton variant="contained"  startIcon={<Save />} loading={loading} onClick={handleSave} disabled={loadingFinished}>
              Save Image
            </LoadingButton>
              </Grid>
              :
              <Grid item>
                <Button startIcon={<Check />} variant="contained" 
                sx={{bgcolor: BrandColors.SUCCESS,
                ':hover' : {bgcolor: "#339933"}}} onClick={props.afterSave}>
                  Finish
                </Button>
              </Grid>
}
            </Grid>
        </>
    )
}