import { useState, useContext, useRef } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  useTheme,
  createTheme,
  ThemeProvider,
  Button,
  SvgIcon,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff} from "@mui/icons-material";
import { BrandColors, BrandType } from "../../Constants";
import "../../Fonts.css";
import { SignInGoogle } from "../Status/GoogleSignIn";
import { LoginUser } from "../Status/LoginUser";
import axios from "axios";
import { ConfirmIfNewUserAndIdentityExists } from "../Status/CheckAuthStatus";
import { CurrentSizeContext } from "../../App";
import { FirebaseError } from "firebase/app";
import { ErrorCodes } from "../Status/ErrorCodes";
import { CreateNewUser, UpdateDisplayName } from "../Status/CreateNewUser";
import { SignInMicrosoft } from "../Status/MicrosoftSignIn";
import { AwaitingAppResponse } from "./AwaitingAppResponse";
import { ForgotPasswordComponent } from "./forgotPassword";
type userInput = {
  email: string;
  password: string;
  fName: string | undefined;
  lName: string | undefined;
};

type LoadingType = {
    password: boolean
    google: boolean,
    microsoft: boolean,
    loginSuccessful: boolean
}
const LoadingDefault = {
    password: false,
    google: false,
    microsoft: false,
    loginSuccessful: false
}

const defaultUserInput = {
  email: "",
  password: "",
  fName: "",
  lName: "",
};

export const Login = (props: any) => {
  const loginRef = useRef<userInput>()
  const CurrentSize = useContext(CurrentSizeContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<LoadingType>(LoadingDefault);
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [userInput, setUserInput] = useState<userInput>(defaultUserInput);
  const [errorMessage, setErrorMessage] = useState("");
  const [createAccountPrompt, setCreateAccountPrompt] = useState(false);
  const OldTheme = useTheme();

  const theme = createTheme({
    palette: OldTheme.palette,
    components: {
      
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: BrandType._WEIGHT.SEMIBOLD,
            textTransform: "none",
            minWidth: '30px',
            
          },
        },
      },
      MuiCircularProgress : {
        styleOverrides: {
          root: {
            height: '10px',
            width: '10px'
          }
        }
      }
    },
  });

  let maxWidth = CurrentSize.tablet ? "300px" : "400px";
  const style = {
    textAlign: "left",
    maxWidth: maxWidth,
  };
  const rowStyle = {
    textAlign: "center",
    width: maxWidth,
    fontWeight: BrandType._WEIGHT.REGULAR,
    justifyContent: "space-between",
    display: "inline-flex",
  };
  const gStyle = {
    justifyContent: "center",
    bgcolor: BrandColors.GRAY.VERY_LIGHT,
    marginY: '3px',
    textAlign: "center",
    width: maxWidth,
    paddingX: '20px',
    color: BrandColors.GRAY.REGULAR,
    fontWeight: BrandType._WEIGHT.SEMIBOLD,
    borderRadius: 0.8
  };

    

  const handleLogin = async () => {
    if (userInput.email !== "" && userInput.password !== "") {
        setLoading({...loading, password: true});
      try {
        let data = await LoginUser(userInput);
        setLoading({
          ...loading,
          loginSuccessful: true
        })
        await ConfirmIfNewUserAndIdentityExists(data.user)

        let token = await data.user.getIdToken();
        try {
          await axios.post(
            "https://identity.fwcpchurch.org/_auth/confirmIdToken",
            {
              idToken: token,
            }
          );
        } catch (e: any) {
            handleErrorMessages(e)
        }
        props.confirmLogin();
      } catch (error: any) {
        setLoading({...loading, password: false});
        if (error instanceof FirebaseError) {
          let err = ErrorCodes.find((x) => x.code == error.code);
          if (err) {
            handleErrorMessages(err.message);
          }
        }
      }
    }
  };

  const handleErrorMessages = (msg : string) => {
    setErrorMessage(msg)
    setTimeout(() => {
        setErrorMessage("")
    }, 5000)
  }
  const handleInput = (input: string, type: string) => {
    setUserInput({ ...userInput, [type]: input });
    loginRef.current = userInput
  };



  const handleEnter = (e: any) => {
    if (e.code === "Enter") {
      handleLogin();
    }
  };

  const changePrompts = () => {
    setCreateAccountPrompt(!createAccountPrompt);
  };


  const googleSignIn = async () => {
    setLoading({...loading, google: true});
    try {
      let data = await SignInGoogle();
      setLoading({
        ...loading,
        loginSuccessful: true
      })
      let token = await data.user.getIdToken();
      await ConfirmIfNewUserAndIdentityExists(data.user)
      try {
        await axios.post(
          "https://identity.fwcpchurch.org/_auth/confirmIdToken",
          {
            idToken: token,
          }
        );
      } catch (e: any) {}
      props.confirmLogin();
    } catch (error) {
        setLoading({...loading, google: false});;
      alert("An Error Occurred.");
    }
  };

  const microsoftSignIn = async () => {
    setLoading({...loading, microsoft: true});;
    try {
      let data = await SignInMicrosoft();
      setLoading({
        ...loading,
        loginSuccessful: true
      })
      let token = await data.user.getIdToken();
      await ConfirmIfNewUserAndIdentityExists(data.user)
      try {
        await axios.post(
          "https://identity.fwcpchurch.org/_auth/confirmIdToken",
          {
            idToken: token,
          }
        );
      } catch (e: any) {
        console.log(e)
      }
      props.confirmLogin();
    } catch (error) {
      setLoading({...loading, microsoft: false});;
      alert("An Error Occurred.");
    }
  };

  const SendToCreateAccount = async () => {
    setLoading({...loading, password: true});;
    await CreateNewUser(userInput?.email, userInput?.password);
    await UpdateDisplayName(userInput.fName + " " + userInput.lName);
    setLoading({...loading, password: false});;

    props.confirmLogin();
  };

  const handleServiceRequest = () => {
    if(createAccountPrompt) {
        SendToCreateAccount()
    } else {
        handleLogin()
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {loading.loginSuccessful ? 
      <AwaitingAppResponse />
      :
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container direction={"column"} rowGap={1.5}>
          { /* <Grid item textAlign={'center'} paddingY={2}>
              <Typography color="error"><b>Pre Production System</b></Typography>
              Please do not use unless explicitly requested to do so.
          </Grid> */
  }
          {createAccountPrompt ? (
            <>
              <Grid item>
                <TextField
                  fullWidth
                  label={"First Name"}
                  sx={style}
                  type="text"
                  onChange={(e) => handleInput(e.target.value, "fName")}
                  placeholder="First Name"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label={"Last Name"}
                  sx={style}
                  type="text"
                  onChange={(e) => handleInput(e.target.value, "lName")}
                  placeholder="Last Name"
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          { showForgotPassword ?
          <Box textAlign={'center'}>
          <ForgotPasswordComponent email={userInput.email} backToLogin={() => setShowForgotPassword(false)}/>
          </Box>
        :
        <>
          <Grid item>
            <TextField
              fullWidth
              sx={style}
              onChange={(e) => handleInput(e.target.value.trim(), "email")}
              onKeyDown={(e) => handleEnter(e)}
              label={"Email"}
              name="username"
              placeholder="Email"
            />
          </Grid>
          <Grid item>
            <TextField
              label={"Password"}
              fullWidth
              name="password"
              onKeyDown={(e) => handleEnter(e)}
              sx={style}
              onChange={(e) => handleInput(e.target.value, "password")}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                fontStyle: "italic",
                color: theme.palette.error.main,
              }}
            >
              {errorMessage}
            </Box>

          </Grid>

          <Grid item>

            <LoadingButton

              variant="contained"
              loading={loading.password}
              type="submit"
              fullWidth
              onClick={handleServiceRequest}
              sx={style}
            >
              {createAccountPrompt ? "Create Account" : "Login"}
            </LoadingButton>
          </Grid>

          <Grid item>
          
            <Box
              sx={{
                width: maxWidth,
                textAlign: "center",
                display: 'inline-block',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <LoadingButton
              
                sx={gStyle}
                onClick={googleSignIn}
                loading={loading.google}
                startIcon={
                  <SvgIcon height="100%" viewBox="0 0 20 20" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                <path d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z" fill="#4285F4"></path>
                <path d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z" fill="#34A853"></path>
                <path d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z" fill="#FBBC05"></path>
                <path d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z" fill="#EA4335"></path>
</SvgIcon>}
              >
                Continue With Google
              </LoadingButton>
              <LoadingButton
              disabled
                sx={gStyle}
                onClick={microsoftSignIn}
                loading={loading.microsoft}
                fullWidth
                startIcon={
                  <SvgIcon height="100%" viewBox="0 0 20 20" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                <g fill="none">
                  <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
                  <path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path>
                  <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
                  <path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900">
                    </path>
                </g>
              </SvgIcon>
                }
              >
              Continue With Microsoft
              </LoadingButton>
            </Box>

          </Grid>
          <Grid item>
            <Box sx={rowStyle}>
              <Button onClick={changePrompts}
              >
                {createAccountPrompt ? "Login Instead" : "Create Account"}
              </Button>
              {createAccountPrompt ? (
                <></>
              ) : (
                <Button onClick={() => setShowForgotPassword(true)}>Forgot Password</Button>
              )}
              
            </Box>
            
          </Grid>
          </>}
        </Grid>
      </form>
}
    </ThemeProvider>
  );
};
