import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { ProfileOptions } from "../ProfileOptions";

export const ProfileBottomNavigation = (props: any) => {
    return (
        <BottomNavigation
        showLabels
        value={props.currentPage.title}
        sx={{
            position: 'fixed',
            bottom: 0,
            width: 1.0
        }}
        >
            {ProfileOptions.map((x, index) => (
                <BottomNavigationAction key={index.toString()} label={x.title} icon={x.icon} 
                value={x.title}
                onClick={() => props.onPageChange(x)}/>
            ))}
        </BottomNavigation>
    )
}