import { TextField, Avatar, Box, Grid, IconButton, Button } from "@mui/material";
import {useState, useContext} from "react"
import { StatusContext } from "../../../../App";
import { CameraAlt, DeleteOutlined } from "@mui/icons-material";
import { ProfileDataType, BrandColors } from "../../../../Constants";
import { useQuery } from "react-query";
import { GetProfileData } from "../../GetProfileData";
import { stringAvatar } from "../../AccountMenu/AccountMenu";
import { MobileModal } from "./MobileModal/MobileModalHome";
import { GetCurrentUserData } from "../../../Status/CheckAuthStatus";
import { MobileConfirmRemovePicture } from "./MobileModal/MobileConfirmDeletePicture";

export const ProfileMobileAccount = () => {
    const {currentStatus, setCurrentStatus} = useContext(StatusContext)
    const [profileData, setProfileData] = useState<ProfileDataType>()
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const {data, isLoading} = useQuery({
        queryKey: ["profileData"],
        queryFn: () => async () =>{
            await getProfileDataFrontEnd()
        }
    })
    const getProfileDataFrontEnd = async() => {
        let profileDta = await GetProfileData(currentStatus.uid)
        setProfileData(profileDta as ProfileDataType)
    }
    
    const handleAfterPictureSave = async () => {
        let newUserData = await GetCurrentUserData()
        setCurrentStatus(newUserData)
        setModalOpen(false)
    }
    
    const personalDataItems = [
        {label: 'First Name', value: profileData?.first_name, id: 'first_name'},
        {label: 'Last Name', value: profileData?.last_name, id:"last_name"},
        {label: 'Email', value: profileData?.email,  id: 'email'}
    ]

    const handleInputChange = (input: string, type: string) => {
        setProfileData({ ...profileData, [type]: input } as ProfileDataType)
    }

    return (
        <>
        <Grid container direction={'row'} spacing={2} paddingX={4} justifyContent={'center'} marginTop={'2vh'}>
            <Grid item textAlign={'center'}>
            <Box>
                <IconButton onClick={() => setModalOpen(true)}>
            <Avatar
                          src={currentStatus?.profile_picture?.toString()}
                          {...stringAvatar(currentStatus?.displayName, "100px", 'calc(25px + 0.8em)')}
                        >
                          {currentStatus.displayName
                            ?.toString()
                            .substring(0, 1)
                            .toUpperCase()}
                            
                        </Avatar>
                        
                        <CameraAlt 
            sx={{
                minWidth: '10px',
                position: 'absolute',
                transform: 'translate(30px, 30px)',
                minHeight: '10px', 
                padding: '10px',
                borderRadius: 20,
                boxShadow: 1,
                bgcolor: BrandColors.WHITE
            }}/>
                        </IconButton>
                        </Box>
                        <Button startIcon={<DeleteOutlined />}
                        onClick={() => setDeleteOpen(true)}>
                    Remove Picture
                </Button>
            </Grid>
            {personalDataItems.map((x, index) => (
                <Grid item width={'inherit'}>
                    <TextField fullWidth key={index.toString()} InputLabelProps={{ shrink: x.value ? true : false }} label={x.label} value={x.value} onChange={(e: any) => handleInputChange(e.target.value, x.id)}/>
                </Grid>
                ))}
            <Grid item>
                <p>Central Auth Member ID: <b>{profileData?.old_member_id}</b></p>
            </Grid>
        </Grid>
        <MobileModal openStatus={modalOpen} handleClose={() => setModalOpen(false)} afterSave={handleAfterPictureSave}/>
        <MobileConfirmRemovePicture deleteOpen={deleteOpen} deleteClose={() => setDeleteOpen(false)}/>
        </>
    )
}
